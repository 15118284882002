<script>
import PaginationBarVue from '@/components/ui/PaginationBar.vue'
import InfoCardVue from '@/components/ui/InfoCard.vue'
import LoadingVue from '@/components/ui/Loading.vue'
import { getManyLab } from '@/api/labs'

import LanguageConverter from '@/util/LanguageConverter'

export default {
  components: { InfoCardVue, LoadingVue, PaginationBarVue },
  data() {
    return {
      labList: [],
      dataCount: 0,
      isLoading: false
    }
  },
  setup() {},
  created() {},
  async mounted() {
    await this.loadLabs()
  },

  unmounted() {},

  methods: {
    LanguageConverter,
    mapLabData(item) {
      return {
        src: item.leadResearcherImgUrl,
        data: {
          title: LanguageConverter({ en: item.labNameEn, kr: item.labName }),
          subtitle: LanguageConverter({ en: item.leadResearcherNameEn, kr: item.leadResearcherName }),
          infoList: [
            {
              head: LanguageConverter({ en: 'Position', kr: '직위' }),
              desc: LanguageConverter({ en: item.leadResearcherPosition, kr: item.leadResearcherPosition })
            },
            {
              head: LanguageConverter({ en: 'Institution', kr: '소속기관' }),
              desc: LanguageConverter({ en: item.affiliatedInstitutionName, kr: item.affiliatedInstitutionName })
            },
            {
              head: LanguageConverter({ en: 'MainField', kr: '분야' }),
              desc: LanguageConverter({ en: item.sixTechnologyMedium, kr: item.sixTechnologyMedium })
            },
            {
              head: LanguageConverter({ en: 'Keywords', kr: '키워드' }),
              desc: LanguageConverter({
                en: item.keywordEn
                  ?.split(',')
                  .slice(0, 3)
                  .reduce((acc, cur) => acc + ',' + cur),
                kr: item.keyword
                  ?.split(',')
                  .slice(0, 3)
                  .reduce((acc, cur) => acc + ',' + cur)
              })
            }
          ],
          link: `/lab/${item.labNo}`
        }
      }
    },

    async loadLabs(pageIdx = 1) {
      this.isLoading = true

      const labs = await getManyLab({ limit: 12, offset: (pageIdx - 1) * 12 })

      if (!(labs.items instanceof Array)) {
        this.isLoading = false
        return
      }

      this.labList = labs.items.map(this.mapLabData)
      this.dataCount = labs.count

      this.isLoading = false
    },

    paginationClickHandler({ itemIdx }) {
      this.loadLabs(itemIdx)
    },
    cardClickHandler({ link }) {
      this.$router.push(link)
    }
  }
}
</script>

<template>
  <LoadingVue v-if="isLoading" />

  <div class="wf__research-results">
    <div class="wf__research-results-head">
      {{ LanguageConverter({ en: 'total', kr: '총' }) }}
      <span class="emphasized">{{ dataCount }}</span>
      {{ LanguageConverter({ en: 'labs', kr: '명' }) }}
    </div>

    <div class="wf__research-results-wrapper">
      <div class="wf__research-results-item wf__card" v-for="(lab, i) in labList" :key="i">
        <InfoCardVue :src="lab.src" :data="lab.data" @onClick="cardClickHandler" />
      </div>
    </div>

    <PaginationBarVue v-if="dataCount !== 0" :totalItems="dataCount" :itemsPerPage="12" @onItemClick="paginationClickHandler" />
  </div>
</template>

<style scoped>
.wf__research-results {
  text-align: left;
  min-height: 800px;
  font-size: var(--wf-text-16);
}
.wf__research-results-wrapper {
  margin-bottom: 3em;
}
.emphasized {
  color: var(--wf-primary-color);
  padding: 0 0.25em;
  font-weight: bold;
}
.wf__research-results-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 1em;
}

.wf__research-results-item {
  overflow-x: hidden;
  max-width: 100%;
  width: 100%;
}

@media screen and (max-width: 992px) {
  .wf__research-results-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .wf__research-results-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
